import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Pager from "../components/Pager"
import ImageModal from "../components/ImageModal"

const Gallery = ({ data, pageContext }) => {
  const [portalStatus, setPortalStatus] = useState(false)
  const [image, setImage] = useState([])
  const imagesInfo = data.allDatoCmsInfinitegallery.edges

  const displayImages = imagesInfo.map(imageInfo => {
    const id = imageInfo.node.id
    const image = getImage(imageInfo.node.infinitegalleryphoto)
    const alt = imageInfo.node.infinitegalleryphoto.alt
    return (
      <GatsbyImage
        key={id}
        image={image}
        alt={alt}
        className="gallery-gatsby-image"
        onClick={() => {
          setImage(imageInfo.node.infinitegalleryphoto)
          setPortalStatus(true)
        }}
      />
    )
  })

  return (
    <>
      <Layout>
        <Seo title="Galeria" />
        <GalleryContainer className={`${portalStatus ? "blur" : ""}`}>
          <GalleryWrapper>{displayImages}</GalleryWrapper>
          <Pager pageContext={pageContext} />
        </GalleryContainer>
        {/* Portal displays modal for images on click (rendering outside of root div) */}
        {portalStatus && (
          <ImageModal>
            <PortalWrapper>
              <PortalCloseButton
                onClick={() => {
                  setPortalStatus(false)
                }}
              ></PortalCloseButton>
              <PortalImageContainer>
                <PortalImage image={getImage(image)} alt={image.alt} />
              </PortalImageContainer>
            </PortalWrapper>
          </ImageModal>
        )}
      </Layout>
    </>
  )
}

export default Gallery

const GalleryContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px - 5vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.blur {
    filter: blur(10px);
  }
`

const GalleryWrapper = styled.div`
  width: 70vw;
  padding: 20px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;

  .gallery-gatsby-image {
    cursor: pointer;
    transition: 0.2s all;
    &:hover {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 1400px) {
    width: 80vw;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 90vw;
    padding: 10px;
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    width: 98vw;
  }
`

const PortalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`

const PortalImageContainer = styled.div`
  animation: fade-in 200ms 0s ease-in-out forwards;

  @keyframes fade-in {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
`

const PortalImage = styled(GatsbyImage)`
  max-width: 90vh;
  aspect-ratio: 1/1;
`

const PortalCloseButton = styled.button`
  position: relative;
  font-size: 3rem;
  font-weight: bold;
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 20px;
  z-index: 999;
  color: white;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s;
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 5px;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: 0.3s;
  }
  &::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 5px;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: 0.3s;
  }
  &:hover {
    border-color: #ccc;
  }
  &:hover::after,
  &:hover::before {
    background-color: #ccc;
  }
`

export const query = graphql`
  query InfiniteGalleryQuery($skip: Int!, $limit: Int!) {
    allDatoCmsInfinitegallery(skip: $skip, limit: $limit) {
      edges {
        node {
          id
          infinitegalleryphoto {
            alt
            createdAt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
