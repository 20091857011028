import ReactDOM from "react-dom"

const ImageModal = props => {
  const el =
    typeof document !== `undefined` ? document.getElementById("portal") : null
  if (el) {
    return ReactDOM.createPortal(props.children, el)
  }
  return null
}

export default ImageModal
