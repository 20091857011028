import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SectionText from "./SectionText"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <PagerWrapper>
      {previousPagePath ? (
        <PagerLink to={previousPagePath}>
          <SectionText className="pager-text">Poprzednia strona</SectionText>
        </PagerLink>
      ) : (
        <div style={{ padding: "20px" }}></div>
      )}
      {nextPagePath ? (
        <PagerLink to={nextPagePath}>
          <SectionText className="pager-text">Następna strona</SectionText>
        </PagerLink>
      ) : (
        <div style={{ padding: "20px" }}></div>
      )}
    </PagerWrapper>
  )
}

export default Pager

const PagerWrapper = styled.div`
  margin: 5vh;
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const PagerLink = styled(Link)`
  padding: 20px;
  text-decoration: none;
  color: black;
  border-bottom: 3px solid #e69597;
  border-right: 3px solid #e69597;
  border-top: 1px solid #f6b597;
  border-left: 1px solid #f6b597;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  .pager-text {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 768px) {
    padding: 12px;
    .pager-text {
      font-size: 1.2rem;
    }
  }
`
